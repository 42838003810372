@use '../abstracts/variables';
.box {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  background-color: #ffffff;
  border-radius: 6px;

  .box-header {
    display: flex;
    align-items: center;
    margin-top: 0;
    padding: 16px 20px;
    font-size: 15px;
    font-weight: bold;
  }
}
