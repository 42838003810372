// login form style
.mat-form-field.mat-focused .mat-form-field-label {
  color: hsl(244deg 25% 45%) !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: hsl(244deg 25% 45%) !important;
}

span.mat-checkbox-label {
  color: hsl(244deg 25% 45%);
}

label.mat-form-field-label {
  font-size: 16px;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 5px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  overflow: inherit;
  mat-error {
    // position: absolute;
    // top: -20px;
    // right: 0;
    text-align: right;
    font-size: 15px;
  }
}
// end of login form style