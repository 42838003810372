.app-menu {
  #dashboard {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: transparent;
    z-index: 2;
    position: absolute;
    padding: 0;
    .dashboard__header {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      background: #3f51b5;
      color: white;
      padding: 0.5rem 2rem;
      min-height: 60px;
      width: 100%;
      background: rgb(97, 67, 133);
      background: linear-gradient(
        90deg,
        rgba(97, 67, 133, 1) 0%,
        rgba(67, 90, 133, 1) 90%
      );
      h1 {
        margin: 0;
        padding: 0;
        font-weight: 400;
        flex: 1;
        margin-left: 20px;
      }
      .icon  {
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .header-action {
      display: flex;

      .action {
        display: grid;
        grid-template-columns: repeat(3, [col] 40px);
        gap: 16px;

        .item {
          &.vertical-line {
            width: 1px;
            background-color: #ffffff;
          }
        }
      }

      .avtar {
        display: flex;
        .avtar-icon {
          border-radius: 50%;
          width: 38px;
          height: 38px;
          background-size: cover;
          object-fit: cover;
        }
      }

      // .profile-menu-btn{
      //   margin: 0 10px;
      // }
    }
    .dash-row {
      display: block;
      width: 100%;
      overflow: auto;
    }
    .dash-row + .dash-row {
      margin: 0rem 1rem;
    }
  }
  .mat-form-field-flex {
    width: 100%;
  }

  .no-wrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word;
    padding-right: 50px !important;
  }

  .mat-dialog-container {
    background: #f8f9fe;
    padding: 50px 80px !important;
    .mat-dialog-actions {
      button.mat-focus-indicator.mat-button.mat-button-base.mat-primary {
        color: #59595b;
      }
      button {
        background: #fff;
        padding: 5px 25px;
      }
    }
  }

  mat-sidenav.mat-drawer.mat-sidenav.example-sidenav.ng-tns-c48-14.ng-trigger.ng-trigger-transform.mat-drawer-side.mat-drawer-opened {
    position: fixed;
    overflow: hidden;
  }
  .example-sidenav .mat-drawer-inner-container {
    overflow: auto;
  }

  .app-container{
    height: 100%;
    overflow: auto;
    // .example-sidenav{
    //   /* width */
    //   ::-webkit-scrollbar {
    //     width: 0px !important;
    //   }

    //   /* Track */
    //   ::-webkit-scrollbar-track {
    //     background: #f1f1f1 !important;
    //   }

    //   /* Handle */
    //   ::-webkit-scrollbar-thumb {
    //     background: #888 !important;
    //   }

    //   /* Handle on hover */
    //   ::-webkit-scrollbar-thumb:hover {
    //     background: #555 !important;
    //   }
    // }
  }

  .mat-list-base .mat-list-item.mat-list-item-with-avatar,
  .mat-list-base .mat-list-option.mat-list-item-with-avatar {
    height: 55px !important;
    display: flex;
    align-items: center;
  }

  mat-nav-list {
    .active {
      border-right: 5px solid #59558e;
      span {
        color: #59558e;
      }
      mat-icon {
        color: #59558e;
      }
    }
    mat-list-item {
      .mat-list-item-content {
        width: 100%;
        // padding: 0px 2em !important;
      }
    }
  }

  .profile-menu-btn{
    color: white;
    margin-right: 4px;
    min-width: unset;
    padding: 2px 8px 2px 0px;
    border-radius: 30px;
  }
}
