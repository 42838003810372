.home-container{
    .content{
        .btn-create-app {
            width: 100%;
        }
        .flex-card-wrap{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .card-item{
                min-width: 100%;
                // height: 160px;
                padding: 10px;
            }

            @media (min-width: 1000px) {
                .card-item{
                    width: 25%;
                    min-width: 300px;
                    // height: 160px;
                    padding: 10px;
                }
            }
        }
    }
}