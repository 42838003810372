@use '../abstracts/space';

.card-wrapper {
  .mat-card {
    box-shadow: none;
    .mat-list {
      .mat-list-item {
        height: inherit;
      }
    }
  }
  .d-flex {
    display: flex;
  }
  .d-flex-row {
    display: flex;
    flex-direction: row;
  }
  .d-flex-column {
    display: flex;
    flex-direction: column;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-space-around {
    justify-content: space-around;
  }
  .justify-space-between {
    justify-content: space-between;
  }
  .justify-space-evenly {
    justify-content: space-evenly;
  }
  .justify-start {
    justify-content: flex-start;
  }
  .justify-end {
    justify-content: flex-end;
  }
  .align-center {
    align-items: center;
  }
  .align-end {
    align-items: right;
  }
  .align-start {
    align-items: left;
  }
  .b-raduis-3 {
    border-radius: 3px;
  }
  .b-raduis-5 {
    border-radius: 5px;
  }
  .b-raduis-10 {
    border-radius: 10px;
  }
  .b-raduis-20 {
    border-radius: 20px;
  }
  .b-raduis-50 {
    border-radius: 50px;
  }
  .width-100 {
    width: 100%;
  }
  .height-100 {
    height: 100%;
  }
  .border {
    border: 1px solid #e7e7e7;
  }
  .scroll-enable {
    overflow-y: scroll;
    height: 175px;
  }
  hr {
    background-color: #e7e7e7;
    height: 1px;
    border: none;
  }
  .mat-list-item-content {
    height: 100% !important;
    width: 100% !important;
  }
  .mat-list-text {
    height: 100%;
    .mat-line {
      height: 100%;
    }
  }

  //can remove in real time
  .d-flex.justify-end {
    svg {
      path {
        fill: #5955ab;
      }
    }
  }
  .s-card {
    position: relative;
    overflow: hidden;
    &::before {
      content: "";
      width: 30px;
      height: 30px;
      background: #5955ab;
      border-radius: 5px;
      position: absolute;
      top: 45%;
      left: -18px;
    }
  }
}
  .flex-card-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    font-size: 16px;

    > a {
      text-decoration: none;
    }

    .card-item {
      display: flex;
      text-align: center;
      // width: 299px;
      // height: 208px;
      // margin:1em;

      .flex-card {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;

        .icon {
          display: block;
          margin-left: auto;
          margin-right: auto;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }
