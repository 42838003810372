// custom space class
.space-0 {
  height: 5px;
  width: 5px;
}
.space-1 {
  height: 10px;
  width: 10px;
}
.space-2 {
  height: 20px;
  width: 20px;
}
.space-3 {
  height: 30px;
  width: 30px;
}
.space-4 {
  height: 40px;
  width: 40px;
}
.space-5 {
  height: 50px;
  width: 50px;
}
//end of custom class
 h1,h2,h3,h4,h5,h6,p {
   white-space: break-spaces;
 }