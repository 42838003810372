
@mixin background-image($url) {
  background-image: url($url);
  background-repeat: no-repeat;
  background-position: center;
}

@mixin background-image-contain($url) {
  @include background-image($url);
  background-size: contain;
}

@mixin background-image-cover($url) {
  @include background-image($url);
  background-size: cover;
}
@mixin hover-overlay($color:#000000){
  &:after{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    pointer-events: none;
    border-radius: inherit;
    transition: opacity 200ms cubic-bezier(0.35, 0, 0.25, 1);
    opacity: 0;
    background-color:$color;
    content:'';
  }
  &:hover{
    &:after{
      opacity:0.08;
    }
  }
}
@mixin status-label($color) {
  color: $color;
  position: relative;
  margin-left: 22px;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -22px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $color;
    transform: translateY(-50%);
  }
}
@mixin academic-completion-status($color, $background-color) {
  background-color: $background-color;
  color: $color;
  padding: 10px 20px;
  border-radius: 12px;
}

@mixin mat-icon-button($size){
        line-height: initial;
        min-width: auto;
        height: #{$size}px;
        width: #{$size}px;
        .mat-button-wrapper {
          line-height: initial;
          mat-icon {
            height: auto;
            width: auto;
            line-height: initial;
            font-size: #{$size * .6}px;
          }
        }
}