
@use '../abstracts/variables' as *;
/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  background-color: #f7f7f7;
}

* {
  box-sizing: border-box;
  font-family: Roboto,Khmer;
}
@font-face {
  font-family: "Khmer";
  src: url("/assets/fonts/KHMEROSBATTAMBANG.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.app-loading {
  z-index: 1050;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #3333;
  top: 0;
  left: 0;
  // background-image: url("/assets/imgs/loading.svg");
  background-image: url("/assets/imgs/mat-loading.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.app-mat-loading {
  z-index: 1050;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #3333;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mat-raised-button {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important;
}
app-main{
  & + *{
    margin-top: 100px;
    margin-left: $menu-width !important;
  }
}


.horizontal {
  width: 100%;
  height: 1px;
  background-color: $color-light-gray;
  margin-bottom: 1rem;
}

.question-circle-icon {
  width: 15px;
  height: 15px;
  margin-left: 16px;
}

.custom-mat-card{
  padding: 16px 32px !important;
  box-shadow: none !important;
  border: 1px solid #eaeaea !important;
  border-radius: 5px !important ;
}


.dot3-icon {
  width: 8px;
  height: 32px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.nowrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.active {
  color: $text-color-active;
}

.mat-dialog-actions{
margin-bottom: 0 !important;
padding: 20px !important;
justify-content: flex-end;
}
.mat-button-base:hover .mat-button-focus-overlay {
  opacity: 8% !important;
}

.mat-form-field-appearance-outline{
  &:not(.mat-form-field-type-mat-date-range-input) {
    .mat-form-field-infix {
      padding: 0.5em 0;
    }
    input.mat-input-element {
      line-height: 30px;
    }
    .mat-form-field-label {
      line-height: 40px;
      top: 1em;
    }
    
    &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
    &.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label{
      transform: translateY(-1em) scale(0.75);
    }

    .mat-select-value {
      line-height: 29px;
    }

    textarea.mat-input-element {
      line-height: 28px;
    }

    .mat-form-field-infix {
      border-top: 0.6em solid transparent;
    }
  }
  &.mat-form-field-type-mat-date-range-input{
    .mat-form-field-label-wrapper{
      top: -1em;
    }
    .mat-date-range-input{
      line-height: 25px;
    }
    .mat-form-field-infix {
      padding: 0.8em 0 0.7em 0;
    }
    .mat-form-field-label {
      line-height: 25px;
    }
    .mat-form-field-infix {
      border-top: 0.6em solid transparent;
    }
    &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
    &.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label{
      transform: translateY(-1em) scale(0.75);
    }
  }

  .mat-form-field-suffix{
    top: unset !important;
  }
}

.mat-form-field-wrapper{
  padding-bottom: 1em;
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: translateY(0%) !important;
}

// to fit it like the original material icon
.mat-flat-button .mat-icon svg{
  padding: 2px;
}


.app-root-content{
  height: 100vh;
  overflow: auto;
}
.dash-row{
  margin-top: 60px;
}
.mat-error, .mat-hint {
  line-height: 16px;
}

.avatar-default{
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #59558e;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #59558e;
}

.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element, .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle, .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}

.no-shadow{
  box-shadow: none !important;
}

.text-blur{
  font-size: $font-size-small;
  color: #969696;
}

/* width */
::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8e8ba8 !important;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #59558e !important;
  border-radius: 5px;
}