@use '../abstracts/variables' as *;

.satellite-app-container{

    .app-icon{
        display: flex;
        justify-content: center;
        align-items: center;

        .icon-background{
            background-color: #f7f7f7;
            border-radius: 10px;
            padding: 10px;

            .icon-image{
                width: 120px;
                height: 120px;
                background-repeat: no-repeat;
                background-size: cover;
                min-width: 120px;
                cursor: pointer;
                border-radius: 10px;
            }
        }

        .btn-cancel-header {
            display: flex;
            height: 150px;
            transform: translate(65px, -10px);
            align-items: flex-start;
            position: absolute;
        }
    }

    .app-secret-button{
        position: absolute;
        right: 0px;
        top: 3px;
    }
    
    .detail-content{
        padding: 15px;

        .title{
            font-size: $font-size-title;
        }

        .description{
            font-size: $font-size-description;
        }
    }
}