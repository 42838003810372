@use '../base/colors' as *;
$color-primary: #514e86;
$color-danger: $rosso-corsa;
$color-incomplete:$tulip-tree;
$color-btn-edit:$tulip-tree;
$color-complete:$malachite;
$color-btn-print:$summer-sky;
$color-academic-complete:$sunset-orange;
$color-btn-delete:$sunset-orange;
$color-btn-request:$topaz;
$color-permission-attendance:$topaz;
$color-purple:$tyrian-purple;
$color-blue:$violet-blue;
$color-semester2:$topaz;
$text-color-light-gray: $gainsboro;
$text-color-gray: $suva-grey;
$text-color-dark-gray: $mortar;
$text-color-active: $grass-green;
$text-color-inactive:$brink-pink;
$text-color-page-title: black;
$color-table-shadow:rgb($summer-sky,0.1);
$color-light-gray: $mercury;
$input-field-color:$grey-chateau;
$input-background-color:$butterfly-bush;
$menu-width: 280px;
$screenMobile: "only screen and (max-width: 600px)";
$screenXS: "only screen and (max-width: 780px)";
$screenXSMaX: "only screen and (max-width: 920px)";
$screenLG: "only screen and (min-width: 1200px)";
$card-shadow:0px 3px 6px rgb(89 85 142 / 10%);
$card-border-radius :10px;

$font-size-title: 18px;
$font-size-sub-title: 16px;
$font-size-description: 14px;
$font-size-small: 12px;