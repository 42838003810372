$rosso-corsa: #ce0000;
$brink-pink:#ff5a73;
$tulip-tree:#E5B32D;
$malachite:#16D367;
$summer-sky:#1AC9E0;
$tyrian-purple:#ae5dff;
$papatinate-blue:#3b2de5;
$gainsboro: #dfdfdf;
$suva-grey: #888888;
$topaz:#11beb7;
$barney:#c905ab;
$mortar: #555555;
$violet-blue:#3a05c9;
$sunset-orange:#ff5d5d;
$grass-green:#13a200;
$mercury: #e7e7e7;
$grey-chateau:#A3A6B4;
$butterfly-bush:#59558E;
