@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;

.app-content{
    display: flex;
    justify-content: center;
    min-height: 0;
    padding: 1rem;
    
    .content{
        width: 100%;
        max-width: 1200px;

        .header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 20px 0;

            .title{
                display: flex;
                align-items: center;
            }

            .filter-block{
                display: flex;
                align-items: center;
            }
        }
    }
}

.common-user-input-field {
    .mat-select-value {
      max-width: unset !important;
    }
    .mat-icon {
      height: 20px;
      width: 20px;
      margin-right: 4px;
      display: inline-block;
      vertical-align: middle;
    }
    .material-icons {
      font-size: 20px;
    }

    line-height: 32px;
    min-width: 200px;
    font-size: 15px;
    font-weight: normal;
    border-radius: 10px;
    background-color: white;
    padding: 0.25em 0.5em;
    border: 1px solid $color-light-gray;
    &:not(:last-child) {
        margin-right: 10px;
    }
}


// .filter-block {
//     &>*:not(:last-child){
//         margin-right: 12px;
//     }
//     .btn-trigger,.search-box,.mat-select{
//         height: 44px;
//         border: 1px solid $color-light-gray;
//         .mat-icon{
//             color: $input-field-color;
//             font-size: 20px;
//             height: 22px;
//             width: 22px;
//         }
//         .mat-select-arrow{
//             color: $input-field-color;
//         }
//     }
//     input,.btn-trigger,select {
//         &:focus {
//             &::placeholder {
//                 color: $input-field-color;
//             }
//         }
//     }
//     .mat-icon {
//         display: inline-block;
//         vertical-align: middle;
//     }

//     display: flex;

//     .btn-trigger {
//         outline: none;
//         font-size: 15px !important;
//         cursor: pointer;
//         border-radius: 10px;
//         padding: 0 10px;
//         background: white;
//         display: flex;
//         align-items: center;
//         .block-name {
//             margin: 0 0.5em;
//             width: 100%;
//             text-align: start;
//             font-size: 15px !important;
//         }
//         .mat-badge-medium.mat-badge-above .mat-badge-content {
//             top: -3px;
//         }
//         .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
//             right: -1px;
//         }
//     }
//     .search-box {
//         border-radius: 10px;
//         padding: 0 8px;
//         background: white;
//         display: flex;
//         align-items: center;
//         width: 250px;
//         min-width: 250px;
//         .mat-icon-button {
//             display: flex;
//             width: 30px ;
//             height: 30px ;
//             align-items: center;
//             justify-content: center;
//             .mat-icon{
//             font-size: 22px ;
//             width: 22px  ;
//             height: 22px ;
//             }
//         }
//         input {
//             width: 100%;
//             font-size: 15px;
//             line-height: inherit;
//             outline: none;
//             border: none;
//             background: transparent;
//         }
//     }
// }