// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
$custom-typography: mat-typography-config(
  $font-family: 'Roboto, Khmer',
);
@include angular-material-typography($custom-typography);
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$mat-custom-violet: (
  50: #ebebf1,
  100: #cdccdd,
  200: #acaac7,
  300: #8b88b0,
  400: #726f9f,
  500: #59558e,
  600: #514e86,
  700: #48447b,
  800: #3e3b71,
  900: #2e2a5f,
  A100: #ada7ff,
  A200: #7d74ff,
  A400: #4d41ff,
  A700: #3528ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
$admin-primary: mat-palette($mat-custom-violet);
$admin-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$admin-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$admin-theme: mat-light-theme(
  (
    color: (
      primary: $admin-primary,
      accent: $admin-accent,
      warn: $admin-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($admin-theme);

.input-unit {
  span {
    color: #888888;
  }
  .mat-form-field-infix {
    display: flex;
  }
}
